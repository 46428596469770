@import '../../styles/dimensions';

.container {
    padding: 0 $gap-small-medium;
    width: 100%;
}

.containerMargin {
    margin-top: $gap-x-large;
}
